import useCategoryList from "features/Category/CategoryList/useCategoryList";
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  IProductData,
  IProductDataAddingDataError,
  IProductDataAddingImage,
  IProductDataAddingImageError,
} from "./Product.types";
import { useTranslation } from "react-i18next";
import { isNumber } from "utils/helpers/global.helpers";
import { useNavigate } from "react-router-dom";
import { useAddProductMutation } from "redux/api/product/product";
import { RouteIdEnum } from "config/enums";
import { useSnackBar } from "hooks/useSnackBar";
import { useGetAllSubCategoriesQuery } from "redux/api/category/category";
import { AltRoute } from "@mui/icons-material";

export default function useAddProductList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const { categoryData } = useCategoryList();

  const [addingData, setAddingData] = useState<IProductData>({
    title: "",
    description: "",
    price: "",
    quantity: {},
    category_product_id: "",
    images: [],
    HT_price: "",
    subCategory_product_id: "",
    tax_id: null,
    color_hex: "",
    TTC_price: "",
    bar_code: "",
    credits: "",
    tag: "",
    status: "1",
    publication_date: "",
    show: "",
    nutriscore: "",
    limit_stock: "",
    min_quantity: "",
    color: "",
    nutrivalues: [],
    provider_id: "",
    marge: "",
    categ_exped: null,
    keyWords: [],
    format: "",
    brand: "",
    garentie: "",
    DateDurabMin: "",
    dateLimiteCons: "",
  });
  const { data: subcategories, isLoading } = useGetAllSubCategoriesQuery({
    id: addingData?.category_product_id,
    all: true,
  });
  const [compteurImages, setCompteurImages] = useState(1);
  const [subCategs, setSubCategs] = useState([]);
  const [addingError, setAddingError] = useState<IProductDataAddingDataError>({
    title: "",
    description: "",
    price: "",
    quantity: "",
    category_product_id: "",
    image: "",
    HT_price: "",
    subCategory_product_id: "",
    tax_id: "",
    color_hex: "",
    TTC_price: "",
    bar_code: "",
    credits: "",
    tag: "",
    status: "",
    publication_date: "",
    show: [],
    nutriscore: "",
    limit_stock: "",
    min_quantity: "",
    provider_id: "",
    nutrivalues: [],
    marge: "",
    categ_exped: "",
    keyWords: [],
    format: "",
    brand: "",
    garentie:"",
    DateDurabMin: "",
    dateLimiteCons: "",
  });
  const [addingImage, setAddingImage] = useState<IProductDataAddingImage[]>([]);
  const [addingOneImage, setAddingOneImage] = useState<IProductDataAddingImage>(
    {
      id: 0,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    }
  );

  const [isMainImageSelected, setIsMainImageSelected] =
    useState<boolean>(false);
  const [addingOneImageError, setAddingOneImageError] =
    useState<IProductDataAddingImageError>({
      color_name: "",
      color_hex: "",
      file: "",
    });

  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingError({ ...addingError, [field]: "" });
        setAddingData({ ...addingData, [field]: e.target.value });
      },
    [addingData, addingError]
  );
  const onChangeQuantity = useCallback(
    (posId: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingError((prevErrors) => ({
          ...prevErrors,
          quantity: "",
        }));
        setAddingData((prevData) => ({
          ...prevData,
          quantity: {
            ...prevData.quantity,
            [posId]: e.target.value,
          },
        }));
      },
    []
  );
  const handleDatePickerChange = (date:any, field: string) => {
    if (date) {
      const formattedDate = date.toISOString().split('T')[0];
      setAddingData({ ...addingData, [field]: formattedDate });
    }
  };
  const onChangeSelectCategory = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, category_product_id: e });
      /*       validateWhenChange()
       */ setSubCategs(subcategories);
      setAddingError({ ...addingError, category_product_id: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectShow = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, show: e });
      setAddingError({ ...addingError, show: [] });
    },
    [addingData, addingError]
  );
  const onChangeSelectProvider = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, provider_id: e });
      setAddingError({ ...addingError, provider_id: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectCategExped = (
    event: SyntheticEvent<Element, Event>,
    newValue: { id: string; label: string } | null
  ) => {
    console.log("newValue", newValue);
    setAddingData({ ...addingData, categ_exped: newValue });
    setAddingError({ ...addingError, categ_exped: "" });
  };
  useEffect(() => {
    console.log("addingData", addingData);
  }, [addingData]);
  const onChangeSelectSubCategory = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, subCategory_product_id: e });
      setAddingError({ ...addingError, subCategory_product_id: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectStatus = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, status: e });

      let IntErrors: any = {
        title: "",
        description: "",
        price: "",
        quantity: "",
        category_product_id: "",
        image: "",
        HT_price: "",
        subCategory_product_id: "",
        tax_id: "",
        color_hex: "",
        TTC_price: "",
        bar_code: "",
        credits: "",
        tag: "",
        status: "",
        publication_date: "",
        show: [],
        nutriscore: "",
        limit_stock: "",
        min_quantity: "",
        provider_id: "",
        nutrivalues: [],
        marge: "",
        categ_exped: "",
        keyWords: [],
        format: "",
        brand: "",
      };
      setAddingError(IntErrors);
    },
    [addingData, addingError]
  );

  const onChangeKeyWords = (e: any, keyWords: any) => {
    setAddingData({ ...addingData, keyWords: keyWords });
    setAddingError({ ...addingError, keyWords: [] });
  };
  const onChangeSelectTax = (
    e: any,
    newValue: { id: number; name: string; percentage: string }
  ) => {
    setAddingData({ ...addingData, tax_id: newValue });
    setAddingError({ ...addingError, tax_id: "" });
  };
  const onChangeSelectBrand = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, brand: e });
      setAddingError({ ...addingError, brand: "" });
    },
    [addingData, addingError]
  );

  const onChangeSelectNutriscore = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, nutriscore: e });
      setAddingError({ ...addingError, nutriscore: "" });
    },
    [addingData, addingError]
  );
  const onChangeOneImage = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const files = (e.target as HTMLInputElement).files;

        if (field === "color_name") {
          setAddingOneImage({
            ...addingOneImage,
            color_name: e.target.value,
          });
        }
        if (field === "color_hex") {
          setAddingOneImage({
            ...addingOneImage,
            color_hex: e.target.value,
          });
        }
        setAddingOneImageError({
          color_name: "",
          color_hex: "",
          file: "",
        });
        if (field === "is_main_image") {
          setAddingOneImage({
            ...addingOneImage,
            is_main_image: addingOneImage.is_main_image ? 0 : 1,
          });
        }

        if (files) {
          const file = files[0];

          // Check if the selected file is an image
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();

            reader.onload = (event) => {
              if (event.target) {
                const image = new Image();
                image.src = event.target.result as string;

                image.onload = () => {
                  // Access the image dimensions
                  const imageWidth = image.width;
                  const imageHeight = image.height;

                  console.log("Image Width:", imageWidth);
                  console.log("Image Height:", imageHeight);
                  if (imageWidth == 300 && imageHeight == 300) {
                    setAddingOneImage({
                      ...addingOneImage,
                      [field]: field === "file" ? file : e.target.value,
                    });
                  } else {
                    setAddingOneImageError({
                      ...addingOneImageError,
                      file: "L'image doit être de taille 300 × 300 pixels.",
                    });
                  }
                };
              }
            };

            // Read the selected file as a data URL
            reader.readAsDataURL(file);
          } else {
            // Handle non-image file type
            console.error("Selected file is not an image.");
          }
        }
      },
    [addingOneImage, addingData, addingError]
  );

  const onChangeOneImage2 = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingOneImage({
          ...addingOneImage,
          [field]: e.target.value,
        });
        setAddingOneImageError({
          color_name: "",
          color_hex: "",
          file: "",
        });
        if (field === "is_main_image") {
          setAddingOneImage({
            ...addingOneImage,
            is_main_image: addingOneImage.is_main_image ? 0 : 1,
          });
        }
        if (field === "file") {
          const files = (e.target as HTMLInputElement).files;
          console.log("fileee", files);

          if (files)
            setAddingOneImage({
              ...addingOneImage,
              file: files[0],
            });
        }
      },
    [addingOneImage]
  );
  const handleDeleteImage = (itemData: any) => {
    console.log("itemData", addingImage);
    setAddingImage((prevState: any) =>
      prevState.filter((item: any) => item.id !== itemData.id)
    );
  };
  useEffect(() => {
    console.log("addingOne", addingOneImage);
  }, [addingOneImage]);
  const onSaveImage = useCallback(() => {
    const newAddingOneImage = {
      ...addingOneImage,
      id: compteurImages + 1,
    };

    // Update addingImage state with the newAddingOneImage
    setAddingImage((prevState) => [...prevState, newAddingOneImage]);

    // Update isMainImageSelected state if necessary
    if (newAddingOneImage.is_main_image) {
      setIsMainImageSelected(true);
    }

    setAddingOneImage({
      id: compteurImages + 1,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    });

    // Increment compteurImages state
    setCompteurImages(compteurImages + 1);

    // Reset addingOneImageError state
    setAddingOneImageError({
      color_name: "",
      color_hex: "",
      file: "",
    });

    // Log the updated addingOneImage
    console.log("aachraff", newAddingOneImage);
  }, [addingOneImage]);

  //addling dialog
  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogContainer = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);

  const closeAddingDialogContainer = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingOneImage({
      id: compteurImages,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    });
    setAddingOneImageError({
      color_name: "",
      color_hex: "",
      file: "",
    });
  }, [setOpenAddingDialog]);

  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("isValidImage", isImageValidate());
    if (isImageValidate()) {
      onSaveImage();
      closeAddingDialogContainer();
    }
  };
  const isImageValidate = () => {
    let ok = true;
    let addingImg = {
      color_name: "",
      color_hex: "",
      file: "",
    };
    // if (addingOneImage.color_hex === "") {
    //   ok = false;
    //   addingImg.color_hex = t("product.color_hex_required");
    // }
    // if (addingOneImage.color_name === "") {
    //   ok = false;
    //   addingImg.color_name = t("product.color_name_required");
    // }
    console.log("addingOneImage.file", addingOneImage);
    if (addingOneImage.file === null) {
      ok = false;
      addingImg.file = t("product.file_required");
    }
    if (!ok) {
      setAddingOneImageError(addingImg);
    }

    return ok;
  };
  const validateWhenChange = () => {
    let addingProduct: any = {
      title: "",
      description: "",
      price: "",
      quantity: "",
      category_product_id: "",
      image: "",
      HT_price: "",
      subCategory_product_id: "",
      tax_id: "",
      credits: "",
      TTC_price: "",
      bar_code: "",
      tag: "",
      status: "",
      publication_date: "",
      show: [],
      nutriscore: "",
      limit_stock: "",
      min_quantity: "",
      nutri_values: [],
      provider_id: "",
      marge: "",
    };
    if (addingData.title == "") {
      addingProduct.title = t("product.title_required");
    } else {
      addingProduct.title = "";
    }
    if (addingData.status != "1") {
      if (!addingData.brand || addingData.brand == "") {
        addingProduct.brand = "Marque obligatoire";
      } else {
        addingProduct.brand = "";
      }
      if (!addingData.description) {
        addingProduct.description = t("product.description_required");
      } else {
        addingProduct.description = "";
      }
      if (!isNumber(addingData.price) || Number(addingData.price) <= 0) {
        addingProduct.price = t("product.price_required");
      } else {
        addingProduct.price = "";
      }

      if (!addingData.category_product_id) {
        addingProduct.category_product_id = t(
          "product.category_product_id_required"
        );
      } else {
        addingProduct.category_product_id = "";
      }
      if (!addingData.subCategory_product_id) {
        addingProduct.subCategory_product_id = t(
          "product.subCategory_product_id_required"
        );
      } else {
        addingProduct.subCategory_product_id = "";
      }
      if (addingData.HT_price === "") {
        addingProduct.HT_price = t("product.price_HT_required");
      } else {
        addingProduct.HT_price = "";
      }
      /*   if (addingData.TTC_price === "") {
      addingProduct.TTC_price = t("product.price_TTC_required");
    } else {
      addingProduct.TTC_price = "";
    } */

      if (addingData.limit_stock === "") {
        addingProduct.limit_stock = t("product.limit_stock_required");
      } else {
        addingProduct.limit_stock = "";
      }
      if (addingData.min_quantity === "") {
        addingProduct.min_quantity = t("product.min_quantity_required");
      } else {
        addingProduct.min_quantity = "";
      }

      if (addingData.marge === "") {
        addingProduct.marge = t("product.marge_required");
      } else {
        addingProduct.marge = "";
      }
      if (
        addingData.category_product_id &&
        !addingData.subCategory_product_id
      ) {
        addingProduct.subCategory_product_id = t("product.must_have_subcat");
      } else if (
        addingData.category_product_id &&
        addingData.subCategory_product_id
      ) {
        addingProduct.subCategory_product_id = "";
      }

      if (
        parseFloat(addingData.credits) < 0 ||
        parseFloat(addingData.credits) > 100
      ) {
        addingProduct.credits = t("product.discount_range");
      } else {
        addingProduct.credits = "";
      }
      if (addingData.min_quantity == "") {
        addingProduct.min_quantity = t("product.min_quantity_required");
      } else {
        addingProduct.min_quantity = "";
      }

      if (
        parseInt(addingData.min_quantity) < 0 ||
        parseInt(addingData.min_quantity) > 100
      ) {
        addingProduct.min_quantity = t("product.min_quantity_range");
      } else {
        addingProduct.min_quantity = "";
      }
      if (!addingData.limit_stock) {
        addingProduct.limit_stock = t("product.limit_stock_required");
      } else {
        addingProduct.limit_stock = "";
      }
      if (
        parseInt(addingData.limit_stock) < 0 ||
        parseInt(addingData.limit_stock) > 100
      ) {
        addingProduct.limit_stock = t("product.limit_stock_range");
      } else {
        addingProduct.limit_stock = "";
      }

      if (addingData.bar_code === "") {
        addingProduct.bar_code = t("product.bar_code_required");
      } else {
        addingProduct.bar_code = "";
      }
      if (addingData.tag === "") {
        addingProduct.tag = t("product.tag_required");
      } else {
        addingProduct.tag = "";
      }
      if (addingData.status === "3" && addingData.publication_date == "") {
        addingProduct.publication_date = t("product.publication_date_required");
      } else {
        addingProduct.publication_date = "";
      }

      if (addingData.status == "") {
        addingProduct.status = t("product.status_required");
      } else {
        addingProduct.status = "";
      }
      if (addingData.show.length === 0) {
        addingProduct.show = [t("product.show_required")];
      } else {
        addingProduct.show = [];
      }

      if (!addingData.categ_exped) {
        addingProduct.categ_exped = "Catégorie expédition obligatoire";
      } else {
        addingProduct.categ_exped = "";
      }
      if (addingData.nutriscore == "") {
        addingProduct.nutriscore = t("product.nutriscore_required");
      } else {
        addingProduct.nutriscore = "";
      }
      if (addingData.provider_id == "") {
        addingProduct.provider_id = t("product.provider_id_required");
      } else {
        addingProduct.provider_id = "";
      }
      if (addingData.tax_id == null) {
        addingProduct.tax_id = t("product.tax_id_required");
      } else {
        addingProduct.tax_id = "";
      }
    }
    setAddingError(addingProduct);

    console.log("addingProdcut", addingProduct);
    console.log("addingData", addingData);
    console.log("addingErr", addingError);
  };

  const isValidate = () => {
    let ok = true;
    let addingProduct: any = {
      title: "",
      description: "",
      price: "",
      quantity: "",
      category_product_id: "",
      image: "",
      HT_price: "",
      subCategory_product_id: "",
      tax_id: "",
      credits: "",
      TTC_price: "",
      bar_code: "",
      tag: "",
      status: "",
      publication_date: "",
      show: [],
      nutriscore: "",
      limit_stock: "",
      min_quantity: "",
      nutri_values: [],
      provider_id: "",
      marge: "",
    };
    if (addingData.title === "") {
      ok = false;
      console.log("grg");
      addingProduct.title = t("product.title_required");
    } else addingProduct.title = "";
    if (addingData.status != "1") {
      if (addingData.description === "") {
        ok = false;
        console.log("dzedzdez");
        addingProduct.description = t("product.description_required");
      } else addingProduct.description = "";

      if (!isNumber(addingData.price) || Number(addingData.price) <= 0) {
        ok = false;
        console.log("fzefzefzefthytt");
        addingProduct.price = t("product.price_required");
      } else {
        addingProduct.price = "";
      }

      if (!addingData.category_product_id) {
        ok = false;
        console.log("ffghzer");
        addingProduct.category_product_id = t(
          "product.category_product_id_required"
        );
      } else {
        addingProduct.category_product_id = "";
      }
      if (!addingData.subCategory_product_id) {
        ok = false;
        console.log("ertygujhithre");
        addingProduct.subCategory_product_id = t(
          "product.subCategory_product_id_required"
        );
      } else {
        addingProduct.subCategory_product_id = "";
      }
      if (addingData.HT_price === "") {
        ok = false;
        console.log("retj");
        addingProduct.HT_price = t("product.price_HT_required");
      } else {
        addingProduct.HT_price = "";
      }
      if (!addingData.tax_id) {
        ok = false;
        console.log("g,n;");
        addingProduct.tax_id = "Taxe obligatoire";
      } else {
        addingProduct.tax_id = "";
      }
      if (!addingData.brand || addingData.brand == "") {
        ok = false;
        addingProduct.brand = "Marque obligatoire";
      } else {
        addingProduct.brand = "";
      }

      /*  if (addingData.limit_stock === "") {
      ok = false;
      console.log("hjkjl");
      addingProduct.limit_stock = t("product.limit_stock_required");
    } else {
      addingProduct.limit_stock = "";
    } */
      /* if (addingData.min_quantity === "") {
      addingProduct.min_quantity = t("product.min_quantity_required");
    } else {
      addingProduct.limit_stock = "";
    } */
      if (Object.keys(addingData.quantity).length === 0) {
        // If the quantity object is empty, no quantity is provided for any POS
        ok = false;
        addingProduct.quantity = t("product.quantity_required");
      } else {
        // Loop through each point of sale's quantity and check if it's valid
        for (const [posId, quantity] of Object.entries(addingData.quantity)) {
          if (quantity === "" || parseInt(quantity, 10) <= 0) {
            ok = false;
            addingProduct.quantity = `${t(
              "product.quantity_required"
            )} for POS ${posId}`; // Display a message for the specific POS
            break; // Stop checking further if one is invalid
          }
        }
      }

      if (ok) {
        addingProduct.limit_stock = "";
      } else {
        // Handle the error case
        console.log("Quantity validation failed:", addingProduct.quantity);
      }
      /* if (addingData.marge === "") {
      addingProduct.marge = t("product.marge_required");
    } else {
      addingProduct.limit_stock = "";
    } */
      if (
        addingData.category_product_id &&
        !addingData.subCategory_product_id
      ) {
        ok = false;
        console.log("rtyuikujyhg");
        addingProduct.subCategory_product_id = t("product.must_have_subcat");
      }

      if (
        (addingData.credits && parseFloat(addingData.credits) < 0) ||
        parseFloat(addingData.credits) > 100
      ) {
        ok = false;
        console.log("ljkjhgfds");
        addingProduct.credits = t("product.discount_range");
      } else {
        addingProduct.credits = "";
      }
      /* if (addingData.min_quantity == "") {
      ok = false;
      console.log("yuiop");
      addingProduct.min_quantity = t("product.min_quantity_required");
    } */

      if (
        parseInt(addingData.min_quantity) < 0 ||
        parseInt(addingData.min_quantity) > 100
      ) {
        ok = false;
        console.log("zerezere");
        addingProduct.min_quantity = t("product.min_quantity_range");
      } else {
        addingProduct.min_quantity = "";
      }
      /* if (!addingData.limit_stock) {
      ok = false;
      console.log("olmlmlj");
      addingProduct.limit_stock = t("product.limit_stock_required");
    } else {
      addingProduct.limit_stock = "";
    } */
      /* if (
      parseInt(addingData.limit_stock) < 0 ||
      parseInt(addingData.limit_stock) > 100
    ) {
      ok = false;

      addingProduct.limit_stock = t("product.limit_stock_range");
    } else {
      addingProduct.limit_stock = "";
    } */

      if (addingData.bar_code == "") {
        ok = false;
        addingProduct.bar_code = t("product.bar_code_required");
      } else {
        addingProduct.bar_code = "";
      }

      if (addingData.status === "3" && addingData.publication_date === "") {
        ok = false;
        addingProduct.publication_date = t("product.publication_date_required");
      } else {
        addingProduct.publication_date = "";
      }

      if (addingData.status == "") {
        ok = false;
        addingProduct.status = t("product.status_required");
      } else {
        addingProduct.status = "";
      }
      if (addingData.show.length == 0) {
        ok = false;
        addingProduct.show.push(t("product.show_required"));
      } else {
        addingProduct.show = [];
      }
      if (!addingData.categ_exped) {
        ok = false;
        addingProduct.categ_exped = "Catégorie expédition obligatoire";
      } else {
        addingProduct.categ_exped = "";
      }

      if (addingData.provider_id == "") {
        ok = false;
        addingProduct.provider_id = t("product.provider_id_required");
      } else {
        addingProduct.provider_id = "";
      }
      if (addingData.images.length === 0) {
        ok = false;
        addingProduct.image = "image is required";
      } else {
        addingProduct.provider_id = "";
      }
    }

    console.log("addingError", addingProduct);
    console.log("addingData", addingData);

    if (!ok) {
      setAddingError(addingProduct);
    }

    return ok;
  };

  const addProductForm = () => {
    addingData.images = [];
    addingImage.map((item, index) => {
      addingData.images.push({
        id: 0,
        file: item.file,
        is_main_image: item.is_main_image,
        color_hex: item.color_hex,
        color_name: item.color_name,
      });
    });
    const data: any = {
      title: addingData.title,
      description: addingData.description,
      price: addingData.price,
      quantity: addingData.quantity,
      provider: addingData.provider_id,
      category_product_id: addingData.category_product_id,
      Subcategory_product_id: addingData.subCategory_product_id,
      nutri_score: addingData.nutriscore,
      display: addingData.show,
      purchase_price: addingData.HT_price,
      price_ttc:
        parseFloat(addingData.price) +
        (parseFloat(addingData.price) *
          parseFloat(addingData.tax_id?.percentage ?? "0")) /
          100,
      barcode: addingData.bar_code,
      discount: addingData.credits,
      label: addingData.tag,
      status: addingData.status,
      publication_date: addingData.publication_date,
      stock_alert: addingData.limit_stock,
      tax_id: addingData.tax_id?.id,
      min_qty: addingData.min_quantity,
      nutritional_values: addingData.nutrivalues,
      images: addingData.images,
      categ_exped: addingData?.categ_exped?.id,
      marge: addingData.marge,
      keyWords: addingData.keyWords,
      format: addingData.format,
      brand: addingData.brand,
      garentie:addingData.garentie,
      dateLimiteCons:addingData.dateLimiteCons,
      DateDurabMin:addingData.DateDurabMin
    };

    console.log("data", data);
    console.log("valid", isValidate());
    if (isValidate()) {
      console.log("data", data);
      addingProduct(data);
    }
  };

  const [addingProduct, { data, error, reset }] = useAddProductMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("product.successfully_adding"));
    reset();
    navigate("/" + RouteIdEnum.Products);
  }

  return {
    categoryData,
    addingData,
    onChange,
    addingError,
    onChangeSelectCategory,
    onChangeSelectNutriscore,
    openAddingDialogContainer,
    addingImage,
    addProductForm,
    openAddingDialog,
    closeAddingDialogContainer,
    onSuccessAddingDialog,
    addingOneImage,
    onChangeOneImage,
    addingOneImageError,
    isMainImageSelected,
    subcategories,
    setAddingData,
    setAddingError,
    onChangeSelectSubCategory,
    onChangeSelectTax,
    onChangeSelectProvider,
    onChangeSelectShow,
    onChangeSelectCategExped,
    onChangeKeyWords,
    onChangeSelectStatus,
    validateWhenChange,
    handleDeleteImage,
    onChangeSelectBrand,
    onChangeQuantity,
    handleDatePickerChange,
  };
}
