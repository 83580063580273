import InputsContainer from "components/common/InputsContainer/InputsContainer";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AddSupplier from "features/Supplier/SupplierAdd/AddSupplier";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { CONFIG, GlobalVariables } from "config/constant";
import CancelIcon from "@mui/icons-material/Cancel";
import { useQuery } from "@tanstack/react-query";
import { getAllPointOfSales } from "UpdatedFeatures/api";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  AutoInputsContainerStyle,
  ContainerButtonStyle,
  DeleteButtonsStyle,
  ImageContainerStyle,
  ImageGalleryStyle,
  ImageListItemStyle,
  ImageStyle,
  InputStyle,
} from "./ProductEdit.style";
import TextInput from "components/common/TextInput/TextInput";
import FileInput from "components/common/FileInput/FileInput";
import Button from "components/common/Button/Button";
import Dialog from "components/common/Dialog/Dialog";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import Table from "components/common/Table/Table";
import { useNavigate } from "react-router-dom";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
} from "../ProductList/Product.style";
import AddButton from "components/common/AddButton/AddButton";
import { ChangeEvent, useCallback, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import DeleteNutriValue from "features/NutriValue/NutriValueDelete/DeleteNutriValue";
import useTaxList from "features/Taxe/TaxeList/useTaxList";
import useSuppliersList from "features/Supplier/SuppliersList/useSuppliersList";
import { ISuppliersData } from "features/Supplier/SuppliersList/Suppliers.types";
import SelectInputNutriScore from "components/common/SelectInput/SelectInputNutriScore";
import SelectInputShow from "components/common/SelectInput/SelectInputShow";
import useExpeditionCategoriesList from "features/ExpeditionCategory/ExpeditionCategList/useExpeditionCategoriesList";
import AddTax from "features/Taxe/TaxAdd/AddTax";
import AutoComplete from "components/common/AutoComplete/AutoComplete";
import AutoCompleteMultipleInput from "components/common/AutoCompleteInputMultiple/AutoCompleteMultipleInput";
import { useUpdateProductMutation } from "redux/api/product/product";
import { useSnackBar } from "hooks/useSnackBar";
import { IBrandData } from "features/Brand/BrandList/Brand.types";
import useBrandList from "features/Brand/BrandList/useBrandList";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItemBar,
  Switch,
} from "@mui/material";
import Box from "@mui/material/Box";

import SelectInput from "components/common/SelectInput/SelectInput";
import { ICategoryData } from "features/Category/CategoryList/Category.types";
import { useParams } from "react-router-dom";
import useEditProductList from "./useEditProductList";
import { Puff } from "react-loader-spinner";
import { PointOfSale } from "./ProductEdit.types";
function ProductEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { brandData } = useBrandList();
  const {
    categoryData,
    editingData,
    editingError,
    onChangeSelectCategory,
    openAddingDialogContainer,
    addingImage,
    addProductForm,
    openAddingDialog,
    closeAddingDialogContainer,
    onSuccessAddingDialog,
    addingOneImage,
    onChangeOneImage,
    addingOneImageError,
    isMainImageSelected,
    productItemData,
    setEditingData,
    setEditingError,
    onChangeSelectSubCategory,
    onChangeSelectTax,
    onChangeSelectProvider,
    onChangeSelectShow,
    onChangeSelectCategExped,
    onChangeKeyWords,
    onChangeSelectStatus,
    onChangeSelectNutriscore,
    onChangeSelectBrand,
    subcategories,
    handleDeleteImage,
    isSuccess,
    handleQuantityChange,
    handleDatePickerChange
  } = useEditProductList({ id: id ?? "" });

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [hasError, sethasError] = useState<boolean>(false);
  useEffect(() => {
    const Error = Object.values(editingError).some((value) => {
      return Array.isArray(value) ? value.length != 0 : value != "";
    });
    sethasError(Error);
    console.log("etet", editingError);
  }, [editingError]);
  const [deleteCode, { data, error, isLoading, reset }] =
    useUpdateProductMutation();

  if (error) {
    displayErrorApiSnackBar(error);
  }
  if (data) {
    displaySuccessSnackBar(t("product.successfully_editing"));
    reset();
  }

  const [showNutri, setShowNutri] = useState(false);
  const [showModalAddProvider, setShowModalAddProvider] =
    useState<boolean>(false);
  const openAddingProviderDialogStatus = useCallback(() => {
    setShowModalAddProvider(true);
  }, []);
  const [taxValue, setTaxValue] = useState<{
    id: number;
    name: string;
    percentage: string;
  } | null>(null);

  const navigate = useNavigate();

  const [openModalTax, setOpenModalTax] = useState(false);

  const [nutrivalue, setNutriValue] = useState({
    id: 0,
    name: "",
    value: "",
  });
  const [showModalAddBrand, setShowModalAddBrand] = useState<boolean>(false);
  const openAddingBrandDialogStatus = useCallback(() => {
    setShowModalAddBrand(true);
  }, []);
  interface Option {
    id: number;
    name: string;
  }

  const options: Option[] = [
    { id: 1, name: "Option 1" },
    { id: 2, name: "Option 2" },
    { id: 3, name: "Option 3" },
  ];
  const { taxesData } = useTaxList();

  const { suppliersData } = useSuppliersList();
  const { catgorievalues } = useExpeditionCategoriesList();
  const [taxes, setTaxes] = useState(taxesData);

  const [inputKeyWord, setInputKeyWord] = useState<string>("");
  const onChangeNutriValue = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNutriValue({ ...nutrivalue, [field]: e.target.value });
      },
    [nutrivalue]
  );

  const onSuccessEditingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    setEditingData((prevItems: any) => {
      if (prevItems.nutritional_values.length > 0) {
        const newArray: any = editingData?.nutritional_values.map((item) =>
          editedNutiValue.id === item.id
            ? {
                id: item.id,
                name: editedNutiValue.name,
                value: editedNutiValue.value,
              }
            : item
        );
        return {
          ...prevItems,
          nutritional_values: newArray,
        };
      }
    });

    setOpenEditNutriValueDialog(false);
  };

  useEffect(() => {
    console.log("editingProd", editingData);
  }, [editingData]);
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditingData({ ...editingData, [field]: e.target.value });
        setEditingError((prevError) => ({
          ...prevError,
          [field]: "",
        }));
      },
    [editingData]
  );
  const handleDelete = (item: string) => {
    setEditingData((prevItems: any) => {
      if (prevItems.keyWords.length > 0) {
        const updatedKeyWords = prevItems?.keyWords.filter(
          (itemKey: string, index: number) => itemKey !== item
        );
        return {
          ...prevItems,
          keyWords: updatedKeyWords,
        };
      }
    });
  };
  const updateNutriValuesAfterDelete = (name: string) => {
    if (editingData.nutritional_values) {
      const nutrivalues: any = editingData?.nutritional_values.filter(
        (nutrival) => nutrival.name !== name
      );
      setEditingData((editingDataPrev: any) => ({
        ...editingDataPrev,
        nutritional_values: nutrivalues,
      }));
    }
  };

  const [counter, setCounter] = useState(1);
  const [openEditNutriValueDialog, setOpenEditNutriValueDialog] =
    useState<boolean>(false);
  const [openDeleteNutriValueDialog, setOpenDeleteNutriValueDialog] =
    useState<boolean>(false);

  const [deletedNutiValue, setDeletedNutiValue] = useState<{
    name: string;
  }>();
  const [editedNutiValue, setEditedNutiValue] = useState<any>();
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter")
      setEditingData((prevState) => ({
        ...prevState,
        keyWords: [...prevState.keyWords, inputKeyWord], // Adding new element to the array
      }));
  };
  useEffect(() => {
    console.log("adding", editingData.keyWords);
  }, [editingData]);
  const openDeleteNutriValueDialogStatus = useCallback(
    (name: string) => () => {
      setOpenDeleteNutriValueDialog(true);
      setDeletedNutiValue({ name: name });
    },
    [setOpenDeleteNutriValueDialog, setDeletedNutiValue]
  );
  const openEditNutriValueDialogStatus = useCallback(
    (id: number, name: string, value: string) => () => {
      setOpenEditNutriValueDialog(true);
      setEditedNutiValue({ id: id, name: name, value: value });
    },
    [setOpenEditNutriValueDialog, setEditedNutiValue]
  );
  const onChangeEditedNutriValue = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditedNutiValue({ ...editedNutiValue, [field]: e.target.value });
      },
    [editedNutiValue]
  );
  const handleInputChange = (event: any, newValue: any) => {
    setEditingData((prevState) => ({
      ...prevState,
      tax_id: newValue, // Adding new element to the array
    }));
    setEditingError((prevState) => ({
      ...prevState,
      tax_id: "", // Adding new element to the array
    }));
  };
  const onChangeDescription = (e: any) => {
    setEditingData({ ...editingData, description: e.target.value });
  };
  const onChangeTitle = (e: any) => {
    setEditingData({ ...editingData, title: e.target.value });
  };
  useEffect(() => {
    console.log("info", productItemData);
  }, [productItemData]);

  const token = localStorage.getItem("token");

  const {
    data: posData,

    isFetching,
    isError,
  } = useQuery({
    queryKey: ["pointOfSales", token],
    queryFn: () => getAllPointOfSales(token),
  });

  const pointsOfSale = posData?.data;
  console.log(pointsOfSale, "eeeh");

  return (
    <>
      <DashboardLayout
        title={t("product.edit")}
        selectedPath={RouteIdEnum.Products}
        backPath={RouteIdEnum.Products}
      >
        {!isSuccess && (
          <Box
            sx={{
              display: "flex",
              height: "75vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {isSuccess && (
          <>
            <InputsContainer title={t("product.info_add")}>
              <Grid container spacing={1}>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    required
                    value={`${editingData?.title}`}
                    label={t("product.title")}
                    placeholder={t("product.title")}
                    onChange={onChange("title")}
                    error={t(editingError?.title)}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <SelectInput
                    label={t("product.status")}
                    error={editingError?.status}
                    value={`${editingData?.status}`}
                    onChange={onChangeSelectStatus("status")}
                    data={[
                      { id: 1, title: "Brouillon" },
                      { id: 2, title: "Publié" },
                      { id: 3, title: "Différé" },
                    ].map((e: any) => ({
                      id: e.id,
                      name: e.title,
                    }))}
                  />
                </AutoInputsContainerStyle>
                {editingData.status == "3" && (
                  <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                    <TextInput
                      value={`${editingData?.publication_date}`}
                      label={t("product.publication_date")}
                      placeholder={t("product.publication_date")}
                      type="date"
                      onChange={onChange("publication_date")}
                      error={t(editingError?.publication_date)}
                    />
                  </AutoInputsContainerStyle>
                )}
                <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
                  <TextInput
                    required={editingData.status == "1" ? false : true}
                    value={`${editingData?.description}`}
                    multiline={true}
                    label={t("product.description")}
                    placeholder={t("product.description")}
                    onChange={onChange("description")}
                    error={t(editingError?.description)}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    required={editingData.status == "1" ? false : true}
                    value={`${editingData?.purchase_price}`}
                    label={t("product.HTprice")}
                    placeholder={t("product.HTprice")}
                    type="number"
                    onChange={onChange("purchase_price")}
                    error={t(editingError?.purchase_price)}
                  />
                </AutoInputsContainerStyle>

                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    required={editingData.status == "1" ? false : true}
                    value={`${editingData?.price}`}
                    label={t("product.price")}
                    type="number"
                    placeholder={t("product.price")}
                    onChange={onChange("price")}
                    error={t(editingError?.price)}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  {taxesData && (
                    <AutoComplete
                      required={editingData.status == "1" ? false : true}
                      label=""
                      data={taxesData?.data?.map((e: any) => ({
                        id: e.id,
                        name: e.name,
                        percentage: e.percentage,
                      }))}
                      getOptionLabel={(option: any) => {
                        return option ? option.name : "Taxe";
                      }}
                      value={
                        editingData?.tax_id ?? {
                          id: 0,
                          name: "Taxe",
                          percentage: "",
                        }
                      }
                      onChange={(event: any, newValue: any) => {
                        console.log("taxval", taxValue);

                        if (newValue === null) {
                          setOpenModalTax(true);
                        }
                        console.log("newValue", newValue);
                        handleInputChange(event, newValue);
                      }}
                      noOptionsText={
                        <div>
                          <button
                            onClick={() => {
                              setOpenModalTax(true);
                            }}
                          >
                            Add
                          </button>
                        </div>
                      }
                      renderInput={(params) => (
                        <TextField
                          required={editingData.status == "1" ? false : true}
                          sx={{
                            backgroundColor: "#fff",
                            "&  .MuiFormHelperText-root.Mui-error": {
                              //<--- here
                              backgroundColor: "#fbf9f9",
                              margin: 0,
                              fontSize: 14,
                            },
                          }}
                          {...params}
                          error={editingError.tax_id !== ""}
                          helperText={editingError.tax_id}
                          label="Taxe"
                        />
                      )}
                    />
                  )}
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    disabled={true}
                    value={`${(
                      parseFloat(editingData.price ?? 0) +
                      (parseFloat(editingData.price ?? 0) *
                        parseFloat(editingData.tax_id?.percentage ?? "0")) /
                        100
                    ).toFixed(2)}`}
                    label={t("product.TTC_price")}
                    placeholder={t("product.TTC_price")}
                    type="number"
                    onChange={onChange("price_ttc")}
                    error={t(editingError?.price_ttc)}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    value={`${editingData?.format}`}
                    label={t("product.product_format")}
                    placeholder={t("product.product_format")}
                    onChange={onChange("format")}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    required={editingData.status == "1" ? false : true}
                    value={`${editingData?.barcode}`}
                    label={t("product.bar_code")}
                    placeholder={t("product.bar_code")}
                    onChange={onChange("barcode")}
                    error={t(editingError?.barcode)}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    value={`${editingData?.discount}`}
                    label={t("product.credits")}
                    type="number"
                    placeholder={t("product.credits")}
                    onChange={onChange("discount")}
                    error={t(editingError?.discount)}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    value={`${editingData?.label}`}
                    label={t("product.tag")}
                    placeholder={t("product.tag")}
                    onChange={onChange("label")}
                    error={t(editingError?.label)}
                  />
                </AutoInputsContainerStyle>

                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <SelectInputShow
                    required={editingData.status == "1" ? false : true}
                    none={true}
                    label={t("product.show")}
                    error={editingError.display[0]}
                    value={editingData.display ?? []}
                    onChange={onChangeSelectShow("show")}
                    data={[
                      { id: 1, title: "Application" },
                      { id: 2, title: "Boutique " },
                      { id: 3, title: "Point de vente " },
                    ].map((e: any) => ({
                      id: e.id,
                      name: e.title,
                    }))}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    type="number"
                    value={`${editingData?.stock_alert}`}
                    label={t("product.limit_stock")}
                    placeholder={t("product.limit_stock")}
                    onChange={onChange("stock_alert")}
                    error={t(editingError?.stock_alert)}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    value={`${editingData?.min_qty}`}
                    type="number"
                    label={t("product.min_quantity")}
                    placeholder={t("product.min_quantity")}
                    onChange={onChange("min_qty")}
                    error={`${t(editingError?.min_qty)}`}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  {categoryData && (
                    <SelectInputNutriScore
                      none={true}
                      label={t("product.nutriscore")}
                      error={editingError?.nutri_score}
                      value={editingData?.nutri_score ?? ""}
                      onChange={onChangeSelectNutriscore("nutriscore")}
                      data={[
                        { id: 1, title: "A", color: "#037d3f" },
                        { id: 2, title: "B", color: "#81b52e" },
                        { id: 3, title: "C", color: "#f6c502" },
                        { id: 4, title: "D", color: "#e77d00" },
                        { id: 5, title: "E", color: "#df3c10" },
                      ]?.map((e: any) => ({
                        id: e.id,
                        name: e.title,
                        color: e.color,
                      }))}
                    />
                  )}
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  {catgorievalues && (
                    <AutoComplete
                      required={editingData.status == "1" ? false : true}
                      label="Catégorie d'expédition"
                      value={editingData?.categ_exped ?? null}
                      error={t(editingError?.categ_exped)}
                      onChange={onChangeSelectCategExped}
                      data={catgorievalues?.data?.map((e: any) => ({
                        id: e.id,
                        label: e.name,
                      }))}
                      renderInput={(params: any) => (
                        <TextField
                          required={editingData.status == "1" ? false : true}
                          {...params}
                          sx={{
                            backgroundColor: "#fff",
                            "&  .MuiFormHelperText-root.Mui-error": {
                              //<--- here
                              backgroundColor: "#fbf9f9",
                              margin: 0,
                              fontSize: 14,
                            },
                          }}
                          error={editingError?.categ_exped}
                          helperText={editingError?.categ_exped}
                          label="Catégories d'expédition"
                          placeholder="Catégories d'expédition"
                        />
                      )}
                    />
                  )}
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  {
                    <AutoCompleteMultipleInput
                      multiple
                      value={editingData?.keyWords ? editingData?.keyWords : []}
                      options={editingData?.keyWords}
                      freeSolo
                      onInputChange={(event: any, newValue: string) =>
                        setInputKeyWord(newValue)
                      }
                      renderTags={(value: string[], getTagProps: any) => {
                        return editingData?.keyWords?.map(
                          (option: string, index: number) => {
                            console.log("option", option);
                            return (
                              <Chip
                                {...getTagProps({ index })}
                                label={option}
                                onDelete={handleDelete.bind(null, option)}
                              />
                            );
                          }
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Mots Clés"
                          onKeyDown={handleKeyDown}
                          placeholder="Mots clés"
                        />
                      )}
                    />
                  }
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <TextInput
                    required={editingData.status == "1" ? false : true}
                    value={`${editingData?.marge}`}
                    label={t("product.marge")}
                    type="number"
                    placeholder={t("product.marge")}
                    onChange={onChange("marge")}
                    error={t(editingError?.marge)}
                  />
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <TextInput
                required={editingData.status == "1" ? false : true}
                value={`${editingData.garentie}`}
                label={t("product.garentie")}
                type="number"
                placeholder={t("product.garentie")}
                onChange={onChange("garentie")}
              />
            </AutoInputsContainerStyle>
            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date Limite De consommation"
                  value={dayjs(editingData.dateLimiteCons)}
                  slotProps={{ textField: { fullWidth: true } }}
                  onChange={(date) => handleDatePickerChange(date,"dateLimiteCons")}
                />
              </LocalizationProvider>
            </AutoInputsContainerStyle>

            <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date De Durabilité Minimale"
                  value={dayjs(editingData.DateDurabMin)}
                  slotProps={{ textField: { fullWidth: true } }}
                  onChange={(date) => handleDatePickerChange(date,"DateDurabMin")}
                />
              </LocalizationProvider>
            </AutoInputsContainerStyle>
              </Grid>
            </InputsContainer>
            <InputsContainer title={t("product.category")}>
              <Grid container spacing={1} style={{ justifyContent: "center" }}>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  {categoryData && (
                    <SelectInput
                      required={editingData.status == "1" ? false : true}
                      none={true}
                      label={t("product.category")}
                      error={editingError?.category_product_id}
                      value={editingData?.category_product_id ?? ""}
                      onChange={onChangeSelectCategory("category_product_id")}
                      data={categoryData.data?.map((e: ICategoryData) => ({
                        id: e.id,
                        name: e.title,
                      }))}
                    />
                  )}
                </AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  {subcategories && (
                    <SelectInput
                      required={editingData.status == "1" ? false : true}
                      none={true}
                      label={t("product.subcategory")}
                      error={editingError?.subCategory_product_id ?? ""}
                      value={editingData?.subCategory_product_id ?? ""}
                      onChange={onChangeSelectSubCategory(
                        "subCategory_product_id"
                      )}
                      data={subcategories.data?.map((e: ICategoryData) => ({
                        id: e.id,
                        name: e.title,
                      }))}
                    />
                  )}
                </AutoInputsContainerStyle>
              </Grid>
            </InputsContainer>
            <InputsContainer title={t("product.quality")}>
  <Grid container spacing={2} style={{ justifyContent: "flex-start" }}>
    {editingData && editingData.points_of_sale && editingData.points_of_sale.length > 0 ? (
      editingData.points_of_sale.map((pos: any) => (
        <Grid
          key={pos.id}
          item
          xs={6}
          container
          alignItems="center"
          style={{ marginBottom: "10px" }}
        >
          <Grid
            item
            xs={6}
            style={{ textAlign: "left", paddingRight: "5px" }}
          >
            <span>{`${pos.name} ${t("product.quantity")}`}</span>
          </Grid>

          <Grid
            item
            xs={6}
            style={{ textAlign: "right", paddingLeft: "5px" }}
          >
            <TextInput
              required={editingData.status === "1" ? false : true}
              value={pos?.quantity || "0"}
              label={t("product.quantity")}
              placeholder={t("product.quantity")}
              type="number"
              onChange={(e) => handleQuantityChange(e, pos.point_of_sale)}
              error={t(editingError?.quantity)}
            />
          </Grid>
        </Grid>
      ))
    ) : (
      <p>{t("No points of sale available")}</p>
    )}
  </Grid>
</InputsContainer>


            <InputsContainer title={"Fournisseur"}>
              <ButtonContainerStyle
                style={{ float: "right" }}
                item
                xs={12}
                sm={4}
              >
                <AddButton
                  onAddClick={openAddingProviderDialogStatus}
                  title={"Ajouter fournisseur"}
                />
              </ButtonContainerStyle>
              <Grid
                container
                spacing={1}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <AutoInputsContainerStyle
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ position: "absolute", top: 0, right: 0 }}
                ></AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  {suppliersData && (
                    <SelectInput
                      required={editingData.status == "1" ? false : true}
                      none={true}
                      label={"Fournisseur"}
                      error={editingError?.provider_id}
                      value={editingData?.provider_id ?? ""}
                      onChange={onChangeSelectProvider("provider_id")}
                      data={suppliersData.data?.map((e: ISuppliersData) => ({
                        id: e.id,
                        name: e.company_name,
                      }))}
                    />
                  )}
                </AutoInputsContainerStyle>
              </Grid>
            </InputsContainer>

            <InputsContainer title={"Marque"}>
              <ButtonContainerStyle
                style={{ float: "right" }}
                item
                xs={12}
                sm={4}
              >
                <AddButton
                  onAddClick={openAddingBrandDialogStatus}
                  title={"Ajouter marque"}
                />
              </ButtonContainerStyle>
              <Grid
                container
                spacing={1}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <AutoInputsContainerStyle
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ position: "absolute", top: 0, right: 0 }}
                ></AutoInputsContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  {brandData && (
                    <SelectInput
                      required={editingData.status == "1" ? false : true}
                      none={true}
                      label={"Marque"}
                      error={editingError.brand}
                      value={editingData.brand ?? ""}
                      onChange={onChangeSelectBrand("brand")}
                      data={brandData.data?.map((e: IBrandData) => ({
                        id: e.id,
                        name: e.name,
                      }))}
                    />
                  )}
                </AutoInputsContainerStyle>
              </Grid>
            </InputsContainer>

            <InputsContainer title={t("product.images_add")}>
              <Grid container spacing={1} style={{ justifyContent: "center" }}>
                <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                  <Button
                    type="submit"
                    title={t("product.image_add")}
                    onClick={openAddingDialogContainer}
                  />
                </AutoInputsContainerStyle>
              </Grid>
              {editingError.images && (
                <span style={{ color: "red" }}>Image obligatoire</span>
              )}
              {addingImage.length > 0 && (
                <ImageList sx={{ maxHeight: 600 }} cols={4} rowHeight={240}>
                  {addingImage.map(
                    (item: any) =>
                      item.file && (
                        <ImageListItemStyle key={item.color_name + item.file}>
                          <IconButton
                            key={item.color_hex}
                            style={{
                              color: "red",
                              position: "absolute",
                              right: "4%",
                            }}
                            onClick={() => handleDeleteImage(item)}
                          >
                            <CancelIcon />
                          </IconButton>
                          <ImageGalleryStyle
                            srcSet={
                              typeof item.file != "string"
                                ? URL.createObjectURL(item.file)
                                : CONFIG.BASE_URL + item.file
                            }
                            src={
                              typeof item.file != "string"
                                ? URL.createObjectURL(item.file)
                                : CONFIG.BASE_URL + item.file
                            }
                            alt={item.color_name}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            sx={{ minHeight: 48 }}
                            title={item.color_name}
                            actionIcon={
                              item.is_main_image && (
                                <IconButton
                                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                  aria-label={`info about`}
                                >
                                  <LibraryAddCheckIcon />
                                </IconButton>
                              )
                            }
                          />
                        </ImageListItemStyle>
                      )
                  )}
                </ImageList>
              )}
            </InputsContainer>

            <Dialog
              open={showNutri}
              handleClose={() => setShowNutri(false)}
              title={t("product.nutriscore_data.add")}
            >
              <form>
                <InputStyle>
                  <TextInput
                    value={nutrivalue?.name}
                    label={t("product.nutriscore_data.name")}
                    onChange={onChangeNutriValue("name")}
                  />
                </InputStyle>
                <InputStyle>
                  <TextInput
                    value={nutrivalue?.value}
                    label={t("product.nutriscore_data.value")}
                    onChange={onChangeNutriValue("value")}
                  />
                </InputStyle>

                <DeleteButtonsStyle>
                  <ContainerButtonStyle>
                    <Button
                      title={t("common.cancel_button")}
                      onClick={() => setShowNutri(false)}
                    />
                  </ContainerButtonStyle>
                  <ContainerButtonStyle>
                    <Button
                      onClick={() => {
                        setEditingData((prevItems: any) => {
                          return {
                            ...prevItems,
                            nutritional_values: [
                              ...prevItems.nutritional_values,
                              {
                                id: counter,
                                name: nutrivalue.name,
                                value: nutrivalue.value,
                              },
                            ],
                          };
                        });

                        setCounter((prevCounter: number) => prevCounter + 1);

                        setShowNutri(false);
                        setNutriValue({
                          id: nutrivalue.id++,
                          name: "",
                          value: "",
                        });
                      }}
                      title={t("common.confirm_button")}
                    />
                  </ContainerButtonStyle>
                </DeleteButtonsStyle>
              </form>
            </Dialog>
            <Dialog
              open={openEditNutriValueDialog}
              handleClose={() => setOpenEditNutriValueDialog(false)}
              title={t("product.nutriscore_data.edit")}
            >
              <form onSubmit={onSuccessEditingDialog}>
                <InputStyle>
                  <TextInput
                    value={editedNutiValue?.name}
                    label={t("product.nutriscore_data.name")}
                    onChange={onChangeEditedNutriValue("name")}
                  />
                </InputStyle>
                <InputStyle>
                  <TextInput
                    value={editedNutiValue?.value}
                    label={t("product.nutriscore_data.value")}
                    onChange={onChangeEditedNutriValue("value")}
                  />
                </InputStyle>

                <DeleteButtonsStyle>
                  <ContainerButtonStyle>
                    <Button
                      title={t("common.cancel_button")}
                      onClick={() => setOpenEditNutriValueDialog(false)}
                    />
                  </ContainerButtonStyle>
                  <ContainerButtonStyle>
                    <Button
                      type="submit"
                      title={t("common.confirm_button")}
                      onClick={(e: any) => {
                        onSuccessEditingDialog(e);
                        window.scrollTo(0, 0);
                      }}
                    />
                  </ContainerButtonStyle>
                </DeleteButtonsStyle>
              </form>
            </Dialog>
            <AddTax
              openAddingDialog={openModalTax}
              setOpenAddingDialog={setOpenModalTax}
            ></AddTax>

            <Dialog
              open={openAddingDialog}
              handleClose={closeAddingDialogContainer}
              title={t("product.image_add")}
            >
              <form onSubmit={onSuccessAddingDialog}>
                <InputStyle></InputStyle>
                {!isMainImageSelected && (
                  <InputStyle>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={addingOneImage?.is_main_image ? true : false}
                          onChange={onChangeOneImage("is_main_image")}
                          name={t("product.is_main_image")}
                        />
                      }
                      label={t("product.is_main_image")}
                    />
                  </InputStyle>
                )}
                <Grid container spacing={1}>
                  <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                    <TextInput
                      value={addingOneImage?.color_name}
                      label={t("product.color_name")}
                      onChange={onChangeOneImage("color_name")}
                      error={t(addingOneImageError?.color_name)}
                    />
                  </AutoInputsContainerStyle>
                  <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
                    <TextInput
                      value={addingOneImage?.color_hex}
                      label={t("product.color_hex")}
                      onChange={onChangeOneImage("color_hex")}
                      error={t(addingOneImageError?.color_hex)}
                      type="color"
                    />
                  </AutoInputsContainerStyle>
                </Grid>

                <InputStyle>
                  <FileInput
                    value={addingOneImage?.file ?? null}
                    label={t("product.file")}
                    onChange={onChangeOneImage("file")}
                    error={t(addingOneImageError?.file)}
                    accept={GlobalVariables.FILE_FORMATS.map(
                      (e) => e.format
                    ).join(",")}
                  />
                </InputStyle>

                {addingOneImage.file && (
                  <ImageContainerStyle>
                    <ImageStyle src={""}></ImageStyle>
                  </ImageContainerStyle>
                )}

                <DeleteButtonsStyle>
                  <ContainerButtonStyle>
                    <Button
                      title={t("common.cancel_button")}
                      onClick={closeAddingDialogContainer}
                    />
                  </ContainerButtonStyle>
                  <ContainerButtonStyle>
                    <Button type="submit" title={t("common.confirm_button")} />
                  </ContainerButtonStyle>
                </DeleteButtonsStyle>
              </form>
            </Dialog>

            <InputsContainer title={"Valeurs nutritionnelles"}>
              <Grid container spacing={1} style={{ justifyContent: "center" }}>
                <ButtonContainerStyle item xs={12} sm={12}>
                  <AddButton
                    onAddClick={() => {
                      setShowNutri(!showNutri);
                    }}
                    title={t("common.add")}
                  />
                </ButtonContainerStyle>
                <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
                  <Table
                    loader={false}
                    headerData={[
                      {
                        key: "name",
                        title: t("product.nutriscore_data.name"),
                      },
                      {
                        key: "value",
                        title: t("product.nutriscore_data.value"),
                      },
                      { key: "action", title: t("common.actions") },
                    ]}
                    bodyData={editingData?.nutritional_values?.map(
                      (categ: { id: number; name: string; value: string }) => {
                        return {
                          name: <p>{categ.name}</p>,
                          value: <p>{categ.value}</p>,
                          action: (
                            <ActionContainerStyle>
                              {
                                <Tooltip title={t("common.edit")}>
                                  <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={openEditNutriValueDialogStatus(
                                      categ.id,
                                      categ.name,
                                      categ.value
                                    )}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              }
                              {
                                <Tooltip title={t("common.delete")}>
                                  <IconButton
                                    color="primary"
                                    component="span"
                                    onClick={openDeleteNutriValueDialogStatus(
                                      categ.name
                                    )}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              }
                            </ActionContainerStyle>
                          ),
                        };
                      }
                    )}
                  />
                </AutoInputsContainerStyle>
              </Grid>
            </InputsContainer>
            <Grid container spacing={1}>
              <ButtonContainerStyle
                item
                xs={12}
                sm={6}
                style={{ justifyContent: "left" }}
              >
                {hasError && (
                  <p
                    style={{
                      position: "relative",
                      color: "red",
                      paddingLeft: 5,
                    }}
                  >
                    <b>{"   "}</b>Une erreur est survenue. Veuillez la corriger
                    et réessayer.
                  </p>
                )}
              </ButtonContainerStyle>
              <ButtonContainerStyle item xs={12} sm={6}>
                <DeleteButtonsStyle>
                  <ContainerButtonStyle>
                    <Button
                      title={t("common.cancel_button")}
                      onClick={() => navigate(-1)}
                    />
                  </ContainerButtonStyle>
                  <ContainerButtonStyle>
                    <Button
                      type="submit"
                      onClick={(e: any) => {
                        addProductForm();
                        sethasError(false);
                      }}
                      title={t("common.confirm_button")}
                    />
                  </ContainerButtonStyle>
                </DeleteButtonsStyle>
              </ButtonContainerStyle>
            </Grid>

            {deletedNutiValue && (
              <DeleteNutriValue
                name={deletedNutiValue?.name}
                openDeleteDialog={openDeleteNutriValueDialog}
                setOpenDeleteDialog={setOpenDeleteNutriValueDialog}
                deleteFunction={() => {
                  updateNutriValuesAfterDelete(deletedNutiValue.name);
                }}
              ></DeleteNutriValue>
            )}

            <AddSupplier
              openAddingDialog={showModalAddProvider}
              addFromProduct={true}
              setOpenAddingDialog={setShowModalAddProvider}
            ></AddSupplier>
          </>
        )}
      </DashboardLayout>
    </>
  );
}
export default ProductEdit;

{
  /*import InputsContainer from "components/common/InputsContainer/InputsContainer";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItemBar,
  Switch,
} from "@mui/material";
import {
  AutoInputsContainerStyle,
  ContainerButtonStyle,
  DeleteButtonsStyle,
  ImageContainerStyle,
  ImageGalleryStyle,
  ImageListItemStyle,
  ImageStyle,
  InputStyle,
} from "./ProductEdit.style";
import TextInput from "components/common/TextInput/TextInput";
import SelectInput from "components/common/SelectInput/SelectInput";
import { ICategoryData } from "features/Category/CategoryList/Category.types";
import FileInput from "components/common/FileInput/FileInput";
import Button from "components/common/Button/Button";
import Dialog from "components/common/Dialog/Dialog";
import { CONFIG, GlobalVariables } from "config/constant";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { useParams } from "react-router-dom";
import useEditProductList from "./useEditProductList";
import { useUpdateProductMutation } from "redux/api/product/product";
import { useSnackBar } from "hooks/useSnackBar";
function ProductEdit() {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    categoryData,
    editingData,
    onChange,
    editingError,
    onChangeSelectCategory,
    openAddingDialogContainer,
    addingImage,
    addProductForm,
    openAddingDialog,
    closeAddingDialogContainer,
    onSuccessAddingDialog,
    addingOneImage,
    onChangeOneImage,
    addingOneImageError,
    isMainImageSelected,
    productItemData,
  } = useEditProductList({ id: id ?? "" });
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [deleteCode, { data, error, isLoading, reset }] =
    useUpdateProductMutation();

  if (error) {
    displayErrorApiSnackBar(error);
  }
  if (data) {
    displaySuccessSnackBar(t("product.successfully_editing"));
    reset();
  }
  return (
    <DashboardLayout
      title={t("product.edit")}
      selectedPath={RouteIdEnum.Products}
      backPath={RouteIdEnum.Products}
    >
      <InputsContainer title={t("product.info_add")}>
        <Grid container spacing={1}>
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <TextInput
              value={editingData.title}
              label={t("product.title")}
              onChange={onChange("title")}
              error={t(editingError.title)}
            />
          </AutoInputsContainerStyle>
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <TextInput
              value={editingData.description}
              label={t("product.description")}
              onChange={onChange("description")}
              error={t(editingError.description)}
            />
          </AutoInputsContainerStyle>
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <TextInput
              value={editingData.price}
              label={t("product.price")}
              onChange={onChange("price")}
              error={t(editingError.price)}
            />
          </AutoInputsContainerStyle>
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <TextInput
              value={editingData.quantity}
              label={t("product.quantity")}
              onChange={onChange("quantity")}
              error={t(editingError.quantity)}
            />
          </AutoInputsContainerStyle>
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            {categoryData && (
              <SelectInput
                none={true}
                label={t("product.category")}
                error={editingError.category_product_id}
                value={editingData.category_product_id ?? ""}
                onChange={onChangeSelectCategory("category_product_id")}
                data={categoryData.data?.map((e: ICategoryData) => ({
                  id: e.id,
                  name: e.title,
                }))}
              />
            )}
          </AutoInputsContainerStyle>
        </Grid>
      </InputsContainer>

      <InputsContainer title={t("product.images_edit")}>
        <h6>{t("product.images_edit_info")}</h6>

        <Grid container spacing={1} style={{ justifyContent: "center" }}>
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <Button
              type="submit"
              title={t("product.image_add")}
              onClick={openAddingDialogContainer}
            />
          </AutoInputsContainerStyle>
        </Grid>

        {addingImage.length > 0 && (
          <ImageList sx={{ maxHeight: 500 }} cols={4} rowHeight={210}>
            {addingImage.map(
              (item) =>
                item.file && (
                  <ImageListItemStyle key={item.color_name + item.file}>
                    <ImageGalleryStyle
                      srcSet={
                        typeof item.file != "string"
                          ? URL.createObjectURL(item.file)
                          : CONFIG.BASE_URL + item.file
                      }
                      src={
                        typeof item.file != "string"
                          ? URL.createObjectURL(item.file)
                          : CONFIG.BASE_URL + item.file
                      }
                      alt={item.color_name}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={item.color_name}
                      actionIcon={
                        item.is_main_image && (
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            aria-label={`info about`}
                          >
                            <LibraryAddCheckIcon />
                          </IconButton>
                        )
                      }
                    />
                  </ImageListItemStyle>
                )
            )}
          </ImageList>
        )}
      </InputsContainer>

      <Grid container spacing={1} style={{ justifyContent: "flex-end" }}>
        <AutoInputsContainerStyle item xs={4} md={4} lg={2}>
          <Button
            type="submit"
            title={t("product.edit")}
            onClick={addProductForm}
          />
        </AutoInputsContainerStyle>
      </Grid>

      <Dialog
        open={openAddingDialog}
        handleClose={closeAddingDialogContainer}
        title={t("product.images_edit")}
      >
        <form onSubmit={onSuccessAddingDialog}>
          {/* <InputStyle>
            <TextInput
              value={addingOneImage.color_name}
              label={t("product.color_name")}
              onChange={onChangeOneImage("color_name")}
              error={t(addingOneImageError.color_name)}
            />
          </InputStyle>
          <InputStyle>
            <TextInput
              value={addingOneImage.color_hex}
              label={t("product.color_hex")}
              onChange={onChangeOneImage("color_hex")}
              error={t(addingOneImageError.color_hex)}
            />
          </InputStyle>
          {!isMainImageSelected && (
            <InputStyle>
              <FormControlLabel
                control={
                  <Switch
                    checked={addingOneImage.is_main_image ? true : false}
                    onChange={onChangeOneImage("is_main_image")}
                    name={t("product.is_main_image")}
                  />
                }
                label={t("product.is_main_image")}
              />
            </InputStyle>
          )}
          <InputStyle>
            <FileInput
              value={
                addingOneImage.file && typeof addingOneImage.file != "string"
                  ? addingOneImage.file
                  : null
              }
              label={t("product.file")}
              onChange={onChangeOneImage("file")}
              error={t(addingOneImageError.file)}
              accept={GlobalVariables.FILE_FORMATS.map((e) => e.format).join(
                ","
              )}
            />
          </InputStyle>
          {addingOneImage.file && (
            <ImageContainerStyle>
              <ImageStyle
                src={
                  typeof addingOneImage.file != "string"
                    ? URL.createObjectURL(addingOneImage.file)
                    : CONFIG.BASE_URL + addingOneImage.file
                }
              ></ImageStyle>
            </ImageContainerStyle>
          )}
          <DeleteButtonsStyle>
            <ContainerButtonStyle>
              <Button
                title={t("common.cancel_button")}
                onClick={closeAddingDialogContainer}
              />
            </ContainerButtonStyle>
            <ContainerButtonStyle>
              <Button type="submit" title={t("common.confirm_button")} />
            </ContainerButtonStyle>
          </DeleteButtonsStyle>
        </form>
      </Dialog>
    </DashboardLayout>
  );
}
export default ProductEdit;*/
}
