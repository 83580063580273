

const FilterButtons = ({ setSelectedFilter, selectedFilter }) => {
    return (
        <div className="inline-flex rounded-md ">
            <button
                onClick={() => setSelectedFilter('')}
                className="px-4 py-2 text-sm font-medium  hover:scale-105 duration-200 text-[#b269ae]"
            >
                Réinstaller
            </button>
            <button
                onClick={() => setSelectedFilter('Annulé')}
                className={`px-4 shadow-md py-2 text-sm font-medium border rounded-s-lg focus:z-10 focus:ring-2 focus:ring-[#b269ae] 
                ${selectedFilter === 'Annulé'
                        ? 'bg-[#b269ae] text-white'
                        : 'text-[#b269ae] border-[#b269ae] hover:bg-[#b269ae] hover:text-white'}
                `}
            >
                Annulé
            </button>
            <button
                onClick={() => setSelectedFilter('En Cours')}
                className={`px-4 shadow-md py-2 text-sm font-medium border focus:z-10 focus:ring-2 focus:ring-[#b269ae] 
                ${selectedFilter === 'En Cours'
                        ? 'bg-[#b269ae] text-white'
                        : 'text-[#b269ae] border-[#b269ae] hover:bg-[#b269ae] hover:text-white'}
                `}
            >
                En Cours
            </button>
            <button
                onClick={() => setSelectedFilter('Terminé')}
                className={`px-4 shadow-md py-2 text-sm font-medium border rounded-e-lg focus:ring-2 focus:ring-[#b269ae] 
                ${selectedFilter === 'Terminé'
                        ? 'bg-[#b269ae] text-white'
                        : 'text-[#b269ae] border-[#b269ae] hover:bg-[#b269ae] hover:text-white'}
                `}
            >
                Terminé
            </button>
        </div>
    );
};

export default FilterButtons;
