import React from "react";
import { RiCloseLine } from "react-icons/ri";

const ProductDetailsModal = ({ isOpen, setIsOpen, closeModal, order }) => {
  if (!isOpen) return null;
console.log(order)
  // Convert products object to array for mapping
  const productsArray = Object.values(order.products || {});

  return (
    <div
      className="fixed inset-0 z-50"
      style={{ zIndex: 9999, overflow: "visible" }}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75"
        aria-hidden="true"
        onClick={closeModal} // Close modal when clicking the overlay
      />
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl p-6 relative max-h-[90vh] overflow-y-auto">
          <div className="relative">
            <div className="bg-[#b269ae] p-6 w-full flex justify-between items-center rounded-t-lg">
              <h1 className="text-left text-xl text-white w-full">
                Détails de la Commande
              </h1>
              <button
                onClick={closeModal}
                className="text-white text-2xl hover:text-gray-200 transition duration-200"
              >
                <RiCloseLine />
              </button>
            </div>
            <div className="mt-6">
              <div className="space-y-20">
                <div>
                  <div className="rounded-lg px-4 py-6 sm:flex justify-between sm:space-x-12 sm:px-6 lg:space-x-12 border-b border-gray-200 pt-4">
                    <div className="flex justify-between sm:block">
                      <dt className="font-semibold text-gray-900 text-lg">
                        Adresse
                      </dt>
                      <dd className="sm:mt-1">
                        <dt >{order.delivery_address}</dt>
                      </dd>
                    </div>
                    <div className="flex justify-between pt-6 sm:block sm:pt-0">
                      <dt className="font-semibold text-gray-900 text-lg">
                        Numéro de Commande
                      </dt>
                      <dd className="sm:mt-1">{order.id}</dd>
                    </div>
                    <div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
                      <dt className="font-semibold text-lg">
                        Mode d'Expédition
                      </dt>
                      <dd className="sm:mt-1">{order.shipping_mode_id}</dd>
                    </div>
                  </div>

                  <table className="mt-4 w-full text-gray-500 sm:mt-6">
                    <thead className="sr-only text-left text-sm text-gray-500 sm:not-sr-only bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3 pr-2 font-semibold text-lg px-4 sm:w-2/5 lg:w-1/3"
                        >
                          Produit
                        </th>
                        <th
                          scope="col"
                          className="hidden w-1/5 py-3 pr-2 font-semibold text-lg sm:table-cell"
                        >
                          Prix
                        </th>
                        <th
                          scope="col"
                          className="hidden py-3 pr-2 font-semibold text-lg sm:table-cell"
                        >
                          Quantité
                        </th>
                        <th
                          scope="col"
                          className="w-0 py-3 pr-2 font-semibold text-lg"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
                      {productsArray.map((prod, index) => (
                        <tr key={index}>
                          <td className="py-6 pr-8">
                            <div className="flex items-center">
                              <img
                                src={`https://api.kdmarche.com/${prod.image}`}
                                alt="product image"
                                className="mr-6 h-28 w-28 rounded object-cover object-center"
                              />
                              <div>
                                <div className="font-medium text-gray-900">
                                  {prod.product_title}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="hidden py-6 pr-8 sm:table-cell">
                            {prod.price_ht}
                          </td>
                          <td className="hidden py-6 pr-8 sm:table-cell">
                            {prod.quantity}
                          </td>
                          <td className="py-6 pr-8 whitespace-nowrap">
                            {prod.total_ttc}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="mt-8 bg-gray-50 rounded-lg p-4 shadow-lg">
                    <dl className="space-y-4">
                      <div className="flex justify-between">
                        <dt className="font-medium text-md text-gray-900">
                          Sous-Total
                        </dt>
                        <dd className="text-md font-medium text-gray-900">
                          €{order.products_ht}
                        </dd>
                      </div>
                      <div className="flex justify-between">
                        <dt className="font-medium text-md text-gray-900">
                          Total Taxes
                        </dt>
                        <dd className="text-md font-medium text-gray-900">
                          €{order.products_tax}
                        </dd>
                      </div>
                      <div className="flex justify-between">
                        <dt className="font-medium text-md text-gray-900">
                          Frais de Livraison
                        </dt>
                        <dd className="text-md font-medium text-gray-900">
                          €{order.shipping_price}
                        </dd>
                      </div>
                      <div className="flex justify-between">
                        <dt className="font-medium text-md text-gray-900">
                          Réduction
                        </dt>
                        <dd className="text-md font-medium text-gray-900">
                          €{order.disount_value || 0}
                        </dd>
                      </div>
                      <div className="flex justify-between border-t pt-4">
                        <dt className="font-semibold text-md text-gray-900">
                          Total
                        </dt>
                        <dd className="text-md font-semibold text-gray-900">
                          €{order.total_ttc}
                        </dd>
                      </div>
                    </dl>

                    <div className="mt-6 text-right"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsModal;
