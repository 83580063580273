import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import { useQuery } from "@tanstack/react-query";
import { getAllPointOfSales } from "UpdatedFeatures/api";

// Define static status options
const statusOptions = [
    { value: 1, label: "Paiement en attente", color: "yellow" },
    { value: 2, label: "En cours de traitement", color: "yellow" },
    { value: 3, label: "En cours de livraison", color: "yellow" },
    { value: 4, label: "Livré", color: "green" },
    { value: 0, label: "Annulé", color: "red" },
    { value: 5, label: "Échec de paiement", color: "red" },
];

const ChangeStatusModal = ({ open, setOpen, order }) => {
    const [selectedStatus, setSelectedStatus] = useState(order?.status || "");
    const [selectedPOS, setSelectedPOS] = useState(order?.pos || "");

    const token = localStorage.getItem("token");

    const { data: pos, isLoading, isError } = useQuery({
        queryKey: ["pointOfSales", token],
        queryFn: () => getAllPointOfSales(token),
        keepPreviousData: true,
    });

    const posData = pos?.data;
    console.log("ammm",posData);

    useEffect(() => {
        if (order) {
            setSelectedStatus(order.status || "");
            setSelectedPOS(order.pos || "");
        }
    }, [order]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle status and POS update

        setOpen(false);
    };

    if (!open) return null;

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading POS data.</div>;

    return (
        <div
            className="fixed inset-0 z-50"
            style={{ zIndex: 9999, overflow: 'visible' }}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            {/* Overlay */}
            <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75"
                aria-hidden="true"
                onClick={() => setOpen(false)} // Close modal when clicking the overlay
            />

            {/* Modal Content */}
            <div className="flex items-center justify-center min-h-screen p-4">
                <div
                    className="relative w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
                    onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
                >
                    {/* Header */}
                    <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30 pb-4">
                        <h3
                            id="modal-title"
                            className="text-lg font-medium leading-6 text-white"
                        >
                            Informations du utilisateur
                        </h3>
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
                            onClick={() => setOpen(false)}
                        >
                            <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>

                    {/* Modal Body */}
                    <div className="p-6">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Status
                                </label>
                                <select
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(Number(e.target.value))}
                                    className="form-select mt-1 block w-full"
                                >
                                    {statusOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Point of Sale
                                </label>
                                <select
                                    value={selectedPOS}
                                    onChange={(e) => setSelectedPOS(Number(e.target.value))}
                                    className="form-select mt-1 block w-full"
                                >
                                    {posData && posData.length > 0 ? (
                                        posData.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option>No POS data available</option>
                                    )}
                                </select>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="bg-[#b269ae] text-white px-4 py-2 rounded"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangeStatusModal;
