import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const productApi = createApi({
  reducerPath: "ProductApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Product","EditProduct"],
  endpoints: (builder) => ({
    getAllProduct: builder.query({
      query: (params: { paginator?: Paginator,all?:boolean,category?:any,Subcategory?:any,Stock?:any}) => {
        return injectParamsPaginator(ENDPOINTS.PRODUCT, params.paginator,params.all,true,params.category,params.Subcategory,params.Stock );
      },
      providesTags: ["Product"],
    }),
    getProduct: builder.query({
      query: (id: number) => ({
        url: `${ENDPOINTS.PRODUCT}/${id}`,
        method: "GET",
      }),
      providesTags: ["EditProduct"],
    }),
    deleteProduct: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.PRODUCT}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Product"],
    }),
    dupliqueProduct: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.PRODUCT}/duplicate/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Product"],
    }),
    deleteMultipleProduct: builder.mutation({
      query: (params: { product_ids: number[], select_all: boolean ,search:string}) => ({
        url: `${ENDPOINTS.PRODUCT}/delete-multiple`,
        method: "POST",
        body: {
          product_ids: params.product_ids,
          select_all: params.select_all,
          search:params.search,
        },
      }),
      invalidatesTags: ["Product"],
    }),
    GetPublishDraftProduct: builder.mutation({
      query: (params: { product_ids: number[], select_all: boolean ,search:string,status:number}) => ({
        url: `${ENDPOINTS.PRODUCT}/change-status-multiple`,
        method: "POST",
        body: {
          product_ids: params.product_ids,
          select_all: params.select_all,
          search:params.search,
          status:params.status
        },
      }),
      invalidatesTags: ["Product","EditProduct"],
    }),
    addProduct: builder.mutation({
      query({ title,
        description,
        price,
        quantity,
        category_product_id,
        images,
        keyWords,
        purchase_price,
        Subcategory_product_id,
        tax_id,
        discount,
        price_ttc,
        barcode,
        label,
        status,
        publication_date,
        display,
        nutri_score,
        stock_alert,
        min_qty,
        marge,
        categ_exped,
        nutritional_values,
        provider,format,brand,garentie,dateLimiteCons,DateDurabMin }) {
        const formData = new FormData();

        formData.append("title", title);
        formData.append("description", description);
        formData.append("price", price);
        console.log('testghjkl',quantity)
        formData.append("quantity", quantity);
        Object.keys(quantity).forEach((value: any, index: number,key:any) => {
          console.log('testghjkl',index)
         
          formData.append("quantities[" + index + "][point_of_sales_id]", value);

          formData.append("quantities[" + index + "][quantity]", quantity[value]);

        });

        
       
        formData.append("category_product_id", category_product_id);
        formData.append("purchase_price", purchase_price);
        formData.append("Subcategory_product_id", Subcategory_product_id);
        formData.append("tax_id", tax_id);
        formData.append("discount", discount);
        formData.append("price_ttc", price_ttc);
        formData.append("barcode", barcode);
        formData.append("label", label);
        formData.append("status", status);
        formData.append("publication_date", publication_date);
        formData.append("display", JSON.stringify(display));
        formData.append("nutri_score", nutri_score);
        formData.append("stock_alert", stock_alert);
        formData.append("min_qty", min_qty);
        formData.append("marge", marge);
        formData.append("format", format);
        formData.append("categ_exped", categ_exped);
        nutritional_values.forEach((nutritional_value: any, index: number) => {
          const nutritionalname = nutritional_value.name;
          const nutritionalvalue = nutritional_value.value;


          formData.append("nutritional_values[" + index + "][name]", nutritionalname);

          formData.append("nutritional_values[" + index + "][value]", nutritionalvalue);

        });
        keyWords.forEach((keyWord: any, index: number) => {




          formData.append("keyWords[" + index + "]", keyWord);

        });
        formData.append("provider", provider);
        formData.append("brand", brand);
        formData.append("garentie", garentie);
        formData.append("dateLimiteCons", dateLimiteCons);
        formData.append("DateDurabMin", DateDurabMin);
        // Handle file uploads for the images field
        images.forEach((image: any, index: number) => {
          const file = image.file;
          const isMainImage = image.is_main_image;
          const colorHex = image.color_hex;
          const colorName = image.color_name;

          formData.append("images[" + index + "][file]", file);
          formData.append("images[" + index + "][is_main_image]", isMainImage);
          formData.append("images[" + index + "][color_hex]", colorHex);
          formData.append("images[" + index + "][color_name]", colorName);
        });

        return {
          url: `${ENDPOINTS.PRODUCT}`,
          method: "POST",
          credentials: "include",
          headers: {
            // If you need to set specific headers for FormData, add them here
            "enctype": "multipart/form-data"
          },
          body: formData,
        };
      },
      invalidatesTags: ["Product"],
    }),
    getProductsByCategory: builder.query({
      query: (id: number) => ({
        url: `${ENDPOINTS.PRODUCT}/by-category/${id}`,
        method: "GET",
      }),
    }),
    updateProduct: builder.mutation({
      query({ id, formData }) {
        return {
          url: `${ENDPOINTS.PRODUCT}/${id}`,
          method: "POST",
          credentials: "include",
          body: formData,
        };
      },
      invalidatesTags: ["Product"],
    }),
    importProduct: builder.mutation({
      query({ formData }) {
        console.log("formData",formData)
        return {
          url: `${ENDPOINTS.PRODUCT}/import`,
          method: "POST",
          credentials: "include",
          body: formData,
        };
      },
      invalidatesTags: ["Product"],
    }),

  }),
  
});

export const {
  useGetAllProductQuery,
  useGetProductQuery,
  useAddProductMutation,
  useDeleteMultipleProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useDupliqueProductMutation,
  useImportProductMutation,
  useGetProductsByCategoryQuery,
  useGetPublishDraftProductMutation
} = productApi;
