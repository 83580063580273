import React from "react";

const Status = ({ text }) => {
    const getStatusStyles = () => {
        switch (text.toLowerCase()) {
            case 'livré':
                return {
                    bgColor: 'bg-green-50',
                    textColor: 'text-green-700',
                    ringColor: 'ring-green-600/20'
                };
            case 'annulé':
                return {
                    bgColor: 'bg-red-50',
                    textColor: 'text-red-700',
                    ringColor: 'ring-red-600/20'
                };
            case 'paiement en attente':
                return {
                    bgColor: 'bg-gray-50',
                    textColor: 'text-gray-700',
                    ringColor: 'ring-gray-600/20'
                };
            case 'en cours de livraison':
                return {
                    bgColor: 'bg-yellow-50',
                    textColor: 'text-yellow-800',
                    ringColor: 'ring-yellow-600/20'
                };
            case 'échec de paiement':
                return {
                    bgColor: 'bg-red-50',
                    textColor: 'text-red-700',
                    ringColor: 'ring-red-600/20'
                };
            case 'en cours de traitement':
                return {
                    bgColor: 'bg-yellow-50',
                    textColor: 'text-yellow-800',
                    ringColor: 'ring-yellow-600/20'
                };
            default:
                return {
                    bgColor: 'bg-gray-50',
                    textColor: 'text-gray-700',
                    ringColor: 'ring-gray-600/20'
                };
        }
    };

    const { bgColor, textColor, ringColor } = getStatusStyles();

    return (
        <span className={`inline-flex items-center rounded-md px-2 py-1 text-md font-medium ${bgColor} ${textColor} ring-1 ring-inset ${ringColor}`}>
            {text}
        </span>
    );
};

export default Status;
