import { useQuery } from "@tanstack/react-query";
import React from "react";
import { RiCloseLine } from "react-icons/ri";
import { generateLoginToken } from "UpdatedFeatures/api";

const UserInformationModal = ({ open, setOpen, user }) => {


    const token = localStorage.getItem("token");
    const clientId = user.id;
    const { data: User } = useQuery({
        queryKey: ['getUserToken', token, clientId],
        queryFn: () => generateLoginToken(clientId, token),
    });
    console.log(User)
    const UserToken = User?.data.token;
    console.log(UserToken)
    const RedirectBase = process.env.REACT_APP_REDIRECT_URL;
    if (!open) return null;
    // Ensure RedirectBase and UserToken are valid
    const handleConnectClick = () => {
        if (UserToken && RedirectBase) {
            const RedirectUrl = `${RedirectBase}?${UserToken}`;
            console.log(RedirectUrl); // Check if URL is correct
            window.location.href = RedirectUrl;
        } else {
            console.error('UserToken or RedirectBase is not available.');
        }
    };

    return (
        <div
            className="fixed inset-0 z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            {/* Overlay */}
            <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75"
                aria-hidden="true"
                onClick={() => setOpen(false)} // Close modal when clicking the overlay
            />

            {/* Modal Content */}
            <div className="flex items-center justify-center min-h-screen p-4">
                <div
                    className="relative w-full max-w-3xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
                    onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
                >
                    {/* Header */}
                    <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30">
                        <h3
                            id="modal-title"
                            className="text-lg font-medium leading-6 text-white"
                        >
                            Informations de l'utilisateur
                        </h3>
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
                            onClick={() => setOpen(false)}
                        >
                            <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>

                    {/* Body */}
                    <div className="p-6 space-y-4">
                        <div className="flex items-center space-x-4">
                            <img
                                src={user.photo_base64}
                                alt={`${user.first_name} ${user.last_name}`}
                                className="h-16 w-16 rounded-full object-cover"
                            />
                            <div>
                                <h4 className="text-xl font-semibold text-gray-900">
                                    {user.first_name} {user.last_name}
                                </h4>
                                <p className="text-gray-500">{user.email}</p>
                                <p className="text-gray-500">{user.phone}</p>
                            </div>
                        </div>

                        <div className="border-t border-gray-200 pt-4">
                            <h5 className="text-lg font-semibold text-gray-900">Informations Complémentaires</h5>
                            <ul className="mt-2 space-y-1 text-gray-700">
                                <li><span className="font-semibold">Adresse de livraison:</span> {user.delivery_address}</li>
                                <li><span className="font-semibold">Ville:</span> {user.city}</li>
                                <li><span className="font-semibold">Code postal:</span> {user.zip_code}</li>
                                <li><span className="font-semibold">Pays:</span> {user.country}</li>
                                <li><span className="font-semibold">Date de naissance:</span> {new Date(user.birth * 1000).toLocaleDateString()}</li>
                                <li><span className="font-semibold">Genre:</span> {user.gender === 'femme' ? 'Femme' : 'Homme'}</li>
                                <li><span className="font-semibold">Type de logement:</span> {user.housing_type}</li>
                                <li><span className="font-semibold">Statut de logement:</span> {user.housing_status}</li>
                                <li><span className="font-semibold">Profession:</span> {user.job}</li>
                            </ul>
                        </div>
                    </div>

                    {/* Footer with Button */}
                    <div className="flex justify-end p-6 bg-gray-50 border-t border-gray-200">
                        <button
                            onClick={handleConnectClick}
                            className="inline-flex items-center justify-center px-6 py-2 bg-[#b269ae] text-white text-sm font-medium rounded-lg shadow hover:bg-[#a3559c] focus:outline-none transition duration-150"
                        >
                            Se Connecter en tant que&nbsp;<b>{user.first_name} {user.last_name}</b>
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserInformationModal;
